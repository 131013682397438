@use '../../node_modules/bulma/sass/utilities/derived-variables' as dv;

.burgerButton{
  height: 35px;
  width: 35px;
}

.impersonating {
  box-shadow: 0 0 20px dv.$danger !important;
}
