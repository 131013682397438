@charset "utf-8";

@use '../../node_modules/bulma/sass' with (
  $body-size: 14px,
  $section-padding: 1.5rem 0.5rem,
  $section-padding-desktop: 1.5rem,
  $section-padding-medium: 1.5rem,
  $section-padding-large: 1.5rem
);

@use '../../node_modules/bulma/sass/utilities/initial-variables' as iv;
@use '../../node_modules/bulma/sass/utilities/derived-variables' as dv;
@use '../../node_modules/bulma/sass/utilities/mixins';
@use '../styles/Switch';
@use '../styles/DatePickers';
@use '../styles/Tooltip';

.indiana-scroll-container {
  cursor: grab;
}

.is-nowrap {
  white-space: nowrap !important;
}

.is-vertical-middle {
  vertical-align: middle !important;
}

.has-background-info{
  color: iv.$black;
}

.has-background-success {
  color: iv.$black;
  th,
  td {
    color: iv.$black;
  }
  a:not(.button) {
    color: hsl(233, 100%, 50%);
  }
}

.has-background-warning {
  color: iv.$black;
  th,
  td {
    color: iv.$black;
  }
  a:not(.button) {
    color: hsl(233, 100%, 60%);
  }
}

.has-background-danger {
  color: iv.$black;
  th,
  td {
    color: iv.$black;
  }
  a:not(.button) {
    color: hsl(233, 100%, 40%);
  }
}

main.section {
  overflow: hidden;
  padding-bottom: 4rem;
  &.standalone {
    margin-top: -3.5rem;
  }
}

.fieldset {
  padding: 1rem;
  border: 1px solid iv.$grey-lighter;
  @media (prefers-color-scheme: dark) {
    border: 1px solid iv.$grey-darker;
  }
}

.fieldset:not(:last-child) {
  margin-bottom: 0.75rem;
}

.legend {
  padding: 0 0.5rem;
  font-weight: bold;
}

.file {
  &.is-loading {
    > .file-label {
      color: transparent;
      box-shadow: none;
      pointer-events: none;
      &::after {
        position: absolute;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        position: absolute !important;
        animation: spinAround 500ms infinite linear;
        border-width: 2px;
        border-style: solid;
        border-radius: 1000px;
        border-color: transparent transparent iv.$grey iv.$grey;
        content: '';
        display: block;
        height: 1em;
        position: relative;
        width: 1em;
        @media (prefers-color-scheme: dark) {
          border-color: transparent transparent iv.$white-ter iv.$white-ter;
        }
      }
    }
    .file-cta {
      & > * {
        opacity: 0;
      }
    }
  }
  .file-input {
    &[disabled] {
      & + .file-cta {
        cursor: not-allowed;
        pointer-events: all;
        border-color: transparent;
        background-color: iv.$white-ter;
        color: iv.$grey;
        @media (prefers-color-scheme: dark) {
          background-color: iv.$grey;
        }
        & > * {
          pointer-events: none;
        }
      }
    }
  }
}

.tabs {
  .is-link {
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    margin-bottom: -1px;
    padding: 0.5em 1em;
    cursor: pointer;
    color: iv.$grey-darker;
    border-bottom-color: iv.$grey-lighter;
    @media (prefers-color-scheme: dark) {
      color: iv.$grey-light;
      border-bottom-color: iv.$grey-darker;
    }
  }
  .is-active {
    .is-link,
    .is-link:hover {
      color: hsl(233, 100%, 63%);
      border-bottom-color: hsl(233, 100%, 63%);
    }
  }
  .is-link:hover {
    color: iv.$black-ter;
    border-bottom-color: iv.$black-ter;
    @media (prefers-color-scheme: dark) {
      color: iv.$white-ter;
      border-bottom-color: iv.$white-ter;
    }
  }
}

.content {
  li {
    &:not(:last-child) {
      ul:last-child {
        margin-bottom: 1rem;
      }
    }
  }
}

.radio {
  margin-right: 0.5em;
  & + .radio {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.table {
  th {
    &:not([align]) {
      text-align: left;
    }
    &.sortable {
      padding: 0;
      button {
        padding: 0.5em 0.75em;
        height: 35px;
        font-weight: inherit;
        width: 100%;
        text-align: left;
        white-space: nowrap;
      }
    }
  }
}
