@use "sass:list"
@use "position"

@use '../../../node_modules/bulma/sass/utilities/mixins'

@each $direction in top, right, bottom, left
	.has-tooltip-#{$direction}-mobile
		+mixins.mobile
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-tablet
		+mixins.tablet
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-tablet-only
		+mixins.tablet-only
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-touch
		+mixins.touch
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-desktop
		+mixins.desktop
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-desktop-only
		+mixins.desktop-only
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-until-widescreen
		+mixins.until-widescreen
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-widescreen
		+mixins.widescreen
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-widescreen-only
		+mixins.widescreen-only
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-until-fullhd
		+mixins.until-fullhd
			@include position.tooltip-direction(#{$direction})
	.has-tooltip-#{$direction}-fullhd
		+mixins.fullhd
			@include position.tooltip-direction(#{$direction})

// Hidden breakpoints
.has-tooltip-hidden-mobile
	+mixins.mobile
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-tablet
	+mixins.tablet
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-tablet-only
	+mixins.tablet-only
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-touch
	+mixins.touch
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-desktop
	+mixins.desktop
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-desktop-only
	+mixins.desktop-only
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-until-widescreen
	+mixins.until-widescreen
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-widescreen
	+mixins.widescreen
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-widescreen-only
	+mixins.widescreen-only
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-until-fullhd
	+mixins.until-fullhd
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
.has-tooltip-hidden-fullhd
	+mixins.fullhd
		&::after,
		&::before
			opacity: 0 !important
			display: none !important

// Text alignement breakpoints
@each $direction in (left, left), (centered, center), (right, right)
	$dir: list.nth($direction, 1)
	$text: list.nth($direction, 2)
	.has-tooltip-text-#{$dir}-mobile
		+mixins.mobile
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-tablet
		+mixins.tablet
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-tablet-only
		+mixins.tablet-only
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-touch
		+mixins.touch
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-desktop
		+mixins.desktop
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-desktop-only
		+mixins.desktop-only
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-until-widescreen
		+mixins.until-widescreen
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-widescreen
		+mixins.widescreen
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-widescreen-only
		+mixins.widescreen-only
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-until-fullhd
		+mixins.until-fullhd
			&::before
				text-align: #{$text}
	.has-tooltip-text-#{$dir}-fullhd
		+mixins.fullhd
			&::before
				text-align: #{$text}