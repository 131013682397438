@use '../../../node_modules/bulma/sass/utilities/initial-variables' as iv
@use '../../../node_modules/bulma/sass/utilities/derived-variables' as dv

$tooltip-animation-duration: .3s !default
$tooltip-animation-transition-timing-function: linear !default
$tooltip-arrow-size: 6px !default
$tooltip-background-color: iv.$grey-dark !default
$tooltip-background-opacity: 0.9 !default
$tooltip-color: iv.$white !default
$tooltip-font-family: dv.$family-primary !default
$tooltip-font-size: iv.$size-7 !default
$tooltip-max-width: 15rem !default
$tooltip-padding: .5rem 1rem !default
$tooltip-radius: iv.$radius-small !default
$tooltip-z-index: 1020 !default