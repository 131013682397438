@use '../../../node_modules/bulma/sass/utilities/initial-variables' as iv
@use '../../../node_modules/bulma/sass/utilities/derived-variables' as dv
@use "sass:color"
@use "sass:list"
@use "sass:meta"
@use 'variables'
@use 'position'
@use 'animation'

=tooltip-arrow
	&::after
		box-sizing: border-box
		color: variables.$tooltip-color
		display: inline-block
		font-family: variables.$tooltip-font-family
		font-size: variables.$tooltip-font-size
		hyphens: auto
		opacity: 0
		overflow: hidden
		pointer-events: none
		position: absolute
		visibility: hidden
		z-index: variables.$tooltip-z-index
		content: ''
		border-style: solid
		border-width: variables.$tooltip-arrow-size
		border-color: rgba(variables.$tooltip-background-color, variables.$tooltip-background-opacity) transparent transparent transparent
		margin-bottom: variables.$tooltip-arrow-size * -1 + 1
	+position.tooltip-arrow-top

=tooltip-box
	&::before
		box-sizing: border-box
		color: variables.$tooltip-color
		display: inline-block
		font-family: variables.$tooltip-font-family
		font-size: variables.$tooltip-font-size
		hyphens: auto
		opacity: 0
		overflow: hidden
		pointer-events: none
		position: absolute
		visibility: hidden
		z-index: variables.$tooltip-z-index
		background: rgba(variables.$tooltip-background-color, variables.$tooltip-background-opacity)
		border-radius: variables.$tooltip-radius
		content: attr(data-tooltip)
		padding: variables.$tooltip-padding
		text-overflow: ellipsis
		white-space: pre
	+position.tooltip-box-top

=tooltip-multiline
	&::before
		height: auto
		width: variables.$tooltip-max-width
		max-width: variables.$tooltip-max-width
		text-overflow: clip
		white-space: normal
		word-break: keep-all

[data-tooltip]
	&:not(.is-loading),
	&:not(.is-disabled),
	&:not([disabled])
		cursor: pointer
		overflow: visible
		position: relative

		+tooltip-box
		&.has-tooltip-arrow
			+tooltip-arrow

		&.has-tooltip-bottom
			+position.tooltip-direction('bottom')

		&.has-tooltip-left
			+position.tooltip-direction('left')

		&.has-tooltip-right
			+position.tooltip-direction('right')

		&.has-tooltip-multiline
			+tooltip-multiline

		&.has-tooltip-text-left
			&::before
				text-align: left
		&.has-tooltip-text-centered
			&::before
				text-align: center
		&.has-tooltip-text-right
			&::before
				text-align: right

		@each $name, $pair in dv.$colors
			$color: list.nth($pair, 1)
			$color-invert: list.nth($pair, 1)
			&.has-tooltip-#{$name}
				&::after
					border-color: rgba($color, variables.$tooltip-background-opacity) transparent transparent transparent !important
				&.has-tooltip-bottom
					&::after
						border-color: transparent transparent rgba($color, variables.$tooltip-background-opacity) transparent !important
				&.has-tooltip-left
					&::after
						border-color: transparent transparent transparent rgba($color, variables.$tooltip-background-opacity) !important
				&.has-tooltip-right
					&::after
						border-color: transparent rgba($color, variables.$tooltip-background-opacity) transparent transparent !important
				&:before
					background-color: rgba($color, variables.$tooltip-background-opacity)
					color: $color-invert

		+position.tooltip-hover
			opacity: 1
			visibility: visible

		&.has-tooltip-fade
			+animation.tooltip-fade

		@include meta.load-css('responsiveness')
	
span
	&[data-tooltip]
		border-bottom: 1px dashed iv.$grey-lighter
		@each $name, $pair in dv.$colors
			$color: list.nth($pair, 1)
			$color-invert: list.nth($pair, 1)
			&.has-tooltip-#{$name}
				border-bottom-color: color.adjust($color, $lightness: 5%)

.control
	span
		&[data-tooltip]
			border-bottom: none
