@use '../../node_modules/bulma/sass/utilities/initial-variables' as iv;

.field {
  .react-datepicker-wrapper {
    height: 2.5em;
    border-radius: 4px;
    background-color: iv.$white;
    border: 1px solid iv.$grey-lighter;
    display: inline-flex;
    overflow: hidden;
    @media (prefers-color-scheme: dark) {
      background-color: iv.$black-ter;
      border-color: iv.$grey-light;
    }
    &:hover {
      border-color: iv.$grey-light;
    }
    .react-datepicker__input-container {
      display: flex;
    }
    input {
      background-color: transparent;
      border: none;
      padding-left: calc(0.625em - 1px);
      padding-right: calc(0.625em - 1px);
      @media (prefers-color-scheme: dark) {
        color: iv.$white-bis;
      }
      &[disabled] {
        background-color: iv.$white-ter;
        cursor: not-allowed;
        @media (prefers-color-scheme: dark) {
          background-color: iv.$grey;
        }
      }
    }
  }
  .has-icons-left {
    .react-datepicker-wrapper {
      input {
        font-size: 14px;
        padding-left: 2.5em;
      }
    }
  }
}

#datepicker-portal {
  position: fixed;
  z-index: 50;
  .react-datepicker {
    display: flex;
  }
}

.react-datepicker-popper {
  z-index: 5;
}

.react-datepicker {
  @media (prefers-color-scheme: dark) {
    background-color: iv.$black-ter;
    color: iv.$white-bis;
  }
  .select {
    select {
      font-size: 1rem;
    }
  }
}

.react-datepicker__header {
  @media (prefers-color-scheme: dark) {
    background-color: iv.$black-bis;
    color: iv.$white-bis;
  }
}

h2.react-datepicker__current-month,
div.react-datepicker__current-month {
  margin-top: -2px;
  @media (prefers-color-scheme: dark) {
    color: iv.$white-bis;
  }
}

.custom-month-container {
  position: relative;
  margin-top: 10px;
  .react-datepicker__current-month {
    line-height: 28px;
    margin-top: 0;
  }
}

.react-datepicker__day-name {
  @media (prefers-color-scheme: dark) {
    color: iv.$white-bis;
  }
}

.react-datepicker__day {
  @media (prefers-color-scheme: dark) {
    color: iv.$white-bis;
  }
  &.react-datepicker__day--keyboard-selected,
  &:hover {
    @media (prefers-color-scheme: dark) {
      color: iv.$black-ter;
    }
  }
  &.react-datepicker__day--disabled {
    pointer-events: none;
    @media (prefers-color-scheme: dark) {
      opacity: 0.5;
    }
  }
}

.react-datepicker__triangle {
  @media (prefers-color-scheme: dark) {
    path + path {
      fill: iv.$black-ter;
      stroke: iv.$black-ter;
    }
  }
}
